<template>
    <div>
        <vue-title title="Контакты | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Контакты</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!contacts">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="contacts" id="form-container">
                        <div v-if="contactMe" class="card mb-5">
                            <div class="card-body">
                                <h5 class="card-title">{{ contactMe.contactName }}</h5>
                                <hr>
                                <div v-if="editContactId == contactMe.contactId && okMessage[contactMe.contactId]" class="alert alert-success mb-3" role="alert">
                                    {{ okMessage[contact.contactId] }}
                                </div>
                                <div v-if="editContactId == contactMe.contactId && errorMessage[contactMe.contactId]" class="alert alert-danger mb-3" role="alert">
                                    <strong>Внимание!</strong> {{ errorMessage[contactMe.contactId] }}
                                </div>
                                <form v-if="formData[contactMe.contactId]" class="contacts-form"  @submit.prevent="onSubmit(contactMe.contactId)">
                                    <DxForm
                                            :id="'contacts-form-'+contactMe.contactId"
                                            :col-count="1"
                                            :form-data="formData[contactMe.contactId]"
                                            :read-only="isReadOnly(contactMe.contactId)"
                                            :show-colon-after-label="true"
                                            :show-validation-summary="true"
                                            :label-location="labelLocation"
                                            :disabled="isEdit[contactMe.contactId]"
                                            @field-data-changed="formFieldDataChanged(contactMe.contactId)"
                                    >
                                        <DxSimpleItem
                                                v-if="!isReadOnly(contactMe.contactId)"
                                                :editor-options="{}"
                                                data-field="contactName"
                                                editor-type="dxTextBox"

                                        >
                                            <DxLabel text="Фамилия Имя"/>
                                            <DxRequiredRule message="Укажите Фамилия Имя"/>
                                        </DxSimpleItem>
                                        <DxGroupItem
                                                :colCount="3"
                                        >
                                            <DxColCountByScreen :xs="1" :sm="3" :md="3" :lg="3"/>
                                            <DxGroupItem
                                                    :colCount="2"
                                                    :colSpan="3"
                                            >
                                                <DxColCountByScreen :xs="1" :sm="2" :md="2" :lg="2"/>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="position"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Должность"/>
                                                </DxSimpleItem>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="responsibility"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Зона ответствености"/>
                                                </DxSimpleItem>
                                            </DxGroupItem>
                                            <DxSimpleItem
                                                    :editor-options="mobileEditorOptions"
                                                    data-field="mobile"
                                                    editor-type="dxTextBox"
                                            >
                                                <!--                                                <DxLabel text="Моб. телефон" :location="$mq == 'sm' || $mq == 'md' ? 'left' : 'top'"/>-->
                                                <DxLabel text="Моб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="phoneEditorOptions"
                                                    data-field="phone"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Раб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="{}"
                                                    data-field="email"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Email" />
                                                <DxEmailRule message="Неверно указан Email"/>
                                                <DxCustomRule message="Укажите Email" type="custom" :reevaluate="true" :validation-callback="validateRequiredEmail" />
                                            </DxSimpleItem>
                                        </DxGroupItem>
                                        <DxGroupItem
                                                v-if="!isReadOnly(contactMe.contactId)"
                                                :colCount="Agent.isAdmin ? 2 : 1"
                                                cssClass="mt-3"
                                        >
                                            <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2"/>
                                            <DxButtonItem
                                                    v-if="Agent.isAdmin"
                                                    horizontalAlignment="left"
                                                    :button-options="{
                                                            text: 'Удалить контакт',
                                                            type: 'danger',
                                                            stylingMode: 'outlined',
                                                            useSubmitBehavior: false,
                                                            onClick: function() {
                                                                delContact(contactMe.contactId);
                                                            }
                                                        }"
                                            />
                                            <DxButtonItem
                                                    :button-options="{
                                                            text: 'Сохранить контакт',
                                                            type: 'default',
                                                            useSubmitBehavior: true
                                                        }"
                                            />
                                        </DxGroupItem>
                                    </DxForm>
                                </form>
                            </div>
                            <div v-if="contactMe.agentId" class="card-body">
                                <h5 class="card-title">О пользователе</h5>
                                <hr>
                                <div class="row">
                                    <div class="col-12 col-md-10 mb-4">
                                        <strong>Логин:</strong> {{ contactMe.logIn }}
                                        <div class="mt-2">
                                            <DxCheckBox
                                                    :id="'adminRight-'+contactMe.agentId"
                                                    :value="contactMe.isAdmin"
                                                    text="АДМИНИСТРАТИВНЫЕ ПРАВА"
                                                    cssClass="dx-checkbox-container-custom"
                                                    @value-changed="adminRight"
                                                    :disabled="isAgentEdit"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 text-right">
                                        <span v-if="contactMe.status == 'Disabled'" class="badge badge-danger font-12" style="line-height: 22px;"><i class="fas fa-user-lock mr-2"></i>Заблокирован</span>
                                        <span v-if="contactMe.status == 'Verified'" class="badge badge-success font-12" style="line-height: 22px;"><i class="fas fa-user-check mr-2"></i>Верифицирован</span>
                                        <span v-if="contactMe.status == 'notVerified'" class="badge badge-warning font-12" style="line-height: 22px;"><i class="fas fa-user-clock mr-2"></i>Не верифицирован</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <DxButton
                                                text="Удалить пользователя"
                                                type="danger"
                                                styling-mode="outlined"
                                                @click="delAgent(contactMe.agentId)"
                                                :disabled="isAgentEdit"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6 text-right pt-1">
                                        <DxButton
                                                text="Блокировать"
                                                type="danger"
                                                styling-mode="outlined"
                                                icon="fas fa-user-lock"
                                                @click="agentDisable(contactMe.agentId)"
                                                :disabled="isAgentEdit || contactMe.status == 'Disabled'"
                                        />
                                        <span class="mx-2"></span>
                                        <DxButton
                                                text="Верифицировать"
                                                type="success"
                                                styling-mode="outlined"
                                                icon="fas fa-user-check"
                                                @click="agentVerified(contactMe.agentId)"
                                                :disabled="isAgentEdit || (contactMe.status != 'notVerified' && contactMe.status == 'Verified')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="Agent.isAdmin" class="text-right pb-3">
                            <DxButton
                                    text="Добавить"
                                    type="success"
                                    styling-mode="contained"
                                    icon="add"
                                    @click="addNewContact"
                            />
                        </div>
                        <div v-if="Agent.isAdmin" id="add-new-contact-div" class="card mb-3 d-none">
                            <div class="card-body">
                                <h5 class="card-title">Добавить новый контакт</h5>
                                <hr>
                                <div v-if="okMessageAddContact" class="alert alert-success mb-3" role="alert">
                                    {{ okMessageAddContact }}
                                </div>
                                <div v-if="errorMessageAddContact" class="alert alert-danger mb-3" role="alert">
                                    <strong>Внимание!</strong> {{ errorMessageAddContact }}
                                </div>
                                <form class="contacts-form"  @submit.prevent="onSubmitAddContact">
                                    <DxForm
                                            id="contacts-form-add"
                                            :col-count="1"
                                            :form-data="formDataAddContact"
                                            :read-only="false"
                                            :show-colon-after-label="true"
                                            :show-validation-summary="true"
                                            :label-location="labelLocation"
                                            :disabled="isAdd"
                                    >
                                        <DxSimpleItem
                                                :editor-options="{}"
                                                data-field="contactName"
                                                editor-type="dxTextBox"

                                        >
                                            <DxLabel text="Имя"/>
                                            <DxRequiredRule message="Укажите Имя"/>
                                        </DxSimpleItem>
                                        <DxGroupItem
                                                :colCount="3"
                                        >
                                            <DxColCountByScreen :xs="1" :sm="3" :md="3" :lg="3"/>
                                            <DxGroupItem
                                                    :colCount="2"
                                                    :colSpan="3"
                                            >
                                                <DxColCountByScreen :xs="1" :sm="2" :md="2" :lg="2"/>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="position"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Должность"/>
                                                </DxSimpleItem>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="responsibility"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Зона ответствености"/>
                                                </DxSimpleItem>
                                            </DxGroupItem>
                                            <DxSimpleItem
                                                    :editor-options="phoneEditorOptions"
                                                    data-field="mobile"
                                                    editor-type="dxTextBox"
                                            >
                                                <!--                                                <DxLabel text="Моб. телефон" :location="$mq == 'sm' || $mq == 'md' ? 'left' : 'top'"/>-->
                                                <DxLabel text="Моб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="phoneEditorOptions"
                                                    data-field="phone"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Раб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="{}"
                                                    data-field="email"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Email" />
                                                <DxEmailRule message="Неверно указан Email"/>
                                                <DxRequiredRule message="Укажите Email"/>
                                            </DxSimpleItem>
                                        </DxGroupItem>
                                        <DxGroupItem
                                                :colCount="8"
                                                cssClass="mt-3"
                                        >
                                            <DxButtonItem
                                                    :colSpan="7"
                                                    :button-options="{
                                                            text: 'Сохранить контакт',
                                                            type: 'default',
                                                            useSubmitBehavior: true
                                                        }"
                                            />
                                            <DxButtonItem
                                                    :button-options="{
                                                            text: 'Отмена',
                                                            type: 'normal',
                                                            useSubmitBehavior: false,
                                                            onClick: addNewContact
                                                        }"
                                            />
                                        </DxGroupItem>
                                    </DxForm>
                                </form>
                            </div>
                        </div>
                        <div class="card mb-3" v-for="contact in contacts" :key="contact.contactId">
                            <div class="card-body">
                                <h5 class="card-title">{{ contact.contactName }}</h5>
                                <hr>
                                <div v-if="editContactId == contact.contactId && okMessage[contact.contactId]" class="alert alert-success mb-3" role="alert">
                                    {{ okMessage[contact.contactId] }}
                                </div>
                                <div v-if="editContactId == contact.contactId && errorMessage[contact.contactId]" class="alert alert-danger mb-3" role="alert">
                                    <strong>Внимание!</strong> {{ errorMessage[contact.contactId] }}
                                </div>
                                <form v-if="formData[contact.contactId]" class="contacts-form"  @submit.prevent="onSubmit(contact.contactId)">
                                    <DxForm
                                            :id="'contacts-form-'+contact.contactId"
                                            :col-count="1"
                                            :form-data="formData[contact.contactId]"
                                            :read-only="isReadOnly(contact.contactId)"
                                            :show-colon-after-label="true"
                                            :show-validation-summary="true"
                                            :label-location="labelLocation"
                                            :disabled="isEdit[contact.contactId]"
                                            @field-data-changed="formFieldDataChanged(contact.contactId)"
                                    >
                                        <DxSimpleItem
                                                v-if="!isReadOnly(contact.contactId)"
                                                :editor-options="{}"
                                                data-field="contactName"
                                                editor-type="dxTextBox"

                                        >
                                            <DxLabel text="Фамилия Имя"/>
                                            <DxRequiredRule message="Укажите Фамилия Имя"/>
                                        </DxSimpleItem>
                                        <DxGroupItem
                                                :colCount="3"
                                        >
                                            <DxColCountByScreen :xs="1" :sm="3" :md="3" :lg="3"/>
                                            <DxGroupItem
                                                    :colCount="2"
                                                    :colSpan="3"
                                            >
                                                <DxColCountByScreen :xs="1" :sm="2" :md="2" :lg="2"/>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="position"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Должность"/>
                                                </DxSimpleItem>
                                                <DxSimpleItem
                                                        :editor-options="{}"
                                                        data-field="responsibility"
                                                        editor-type="dxTextBox"

                                                >
                                                    <DxLabel text="Зона ответствености"/>
                                                </DxSimpleItem>
                                            </DxGroupItem>
                                            <DxSimpleItem
                                                    :editor-options="mobileEditorOptions"
                                                    data-field="mobile"
                                                    editor-type="dxTextBox"
                                            >
<!--                                                <DxLabel text="Моб. телефон" :location="$mq == 'sm' || $mq == 'md' ? 'left' : 'top'"/>-->
                                                <DxLabel text="Моб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="phoneEditorOptions"
                                                    data-field="phone"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Раб. телефон" />
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem
                                                    :editor-options="{}"
                                                    data-field="email"
                                                    editor-type="dxTextBox"
                                            >
                                                <DxLabel text="Email" />
                                                <DxEmailRule message="Неверно указан Email"/>
                                                <DxRequiredRule message="Укажите Email"/>
                                                <DxCustomRule message="Укажите Email" type="custom" :reevaluate="true" :validation-callback="validateRequiredEmail" />
                                            </DxSimpleItem>
                                        </DxGroupItem>
                                        <DxGroupItem
                                                v-if="!isReadOnly(contact.contactId)"
                                                :colCount="Agent.isAdmin ? 2 : 1"
                                                cssClass="my-3"
                                        >
                                            <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2"/>
                                            <DxButtonItem
                                                    v-if="Agent.isAdmin"
                                                    horizontalAlignment="left"
                                                    :button-options="{
                                                            text: 'Удалить контакт',
                                                            type: 'danger',
                                                            stylingMode: 'outlined',
                                                            useSubmitBehavior: false,
                                                            onClick: function() {
                                                                delContact(contact.contactId);
                                                            }
                                                        }"
                                            />
                                            <DxButtonItem
                                                    :button-options="{
                                                            text: 'Сохранить контакт',
                                                            type: 'default',
                                                            useSubmitBehavior: true
                                                        }"
                                            />
                                        </DxGroupItem>
                                    </DxForm>
                                </form>
                            </div>
                            <div v-if="contact.agentId" class="card-body">
                                <h5 class="card-title">О пользователе</h5>
                                <hr>
                                <div class="row">
                                    <div class="col-12 col-md-10 mb-4">
                                        <strong>Логин:</strong> {{ contact.logIn }}
                                        <div class="mt-2">
                                            <DxCheckBox
                                                    :id="'adminRight-'+contact.agentId"
                                                    :value="contact.isAdmin"
                                                    text="АДМИНИСТРАТИВНЫЕ ПРАВА"
                                                    cssClass="dx-checkbox-container-custom"
                                                    @value-changed="adminRight"
                                                    :disabled="isAgentEdit"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 text-right">
                                        <span v-if="contact.status == 'Disabled'" class="badge badge-danger font-12" style="line-height: 22px;"><i class="fas fa-user-lock mr-2"></i>Заблокирован</span>
                                        <span v-if="contact.status == 'Verified'" class="badge badge-success font-12" style="line-height: 22px;"><i class="fas fa-user-check mr-2"></i>Верифицирован</span>
                                        <span v-if="contact.status == 'notVerified'" class="badge badge-warning font-12" style="line-height: 22px;"><i class="fas fa-user-clock mr-2"></i>Не верифицирован</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <DxButton
                                                text="Удалить пользователя"
                                                type="danger"
                                                styling-mode="outlined"
                                                @click="delAgent(contact.agentId)"
                                                :disabled="isAgentEdit"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6 text-right pt-1">
                                        <DxButton
                                                text="Блокировать"
                                                type="danger"
                                                styling-mode="outlined"
                                                icon="fas fa-user-lock"
                                                @click="agentDisable(contact.agentId)"
                                                :disabled="isAgentEdit || contact.status == 'Disabled'"
                                        />
                                        <span class="mx-2"></span>
                                        <DxButton
                                                text="Верифицировать"
                                                type="success"
                                                styling-mode="outlined"
                                                icon="fas fa-user-check"
                                                @click="agentVerified(contact.agentId)"
                                                :disabled="isAgentEdit || (contact.status != 'notVerified' && contact.status == 'Verified')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import localStore from './store';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import {
        DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel,
        DxColCountByScreen,
        DxButtonItem,
        DxRequiredRule,
        DxPatternRule,
        DxEmailRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import DxButton from 'devextreme-vue/button';
    import { DxCheckBox } from 'devextreme-vue/check-box';

    export default {
        name: 'ProfileContacts',
        components: {
            Header,
            Footer,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxLabel,
            DxColCountByScreen,
            DxLoadIndicator,
            DxButtonItem,
            DxRequiredRule,
            DxPatternRule,
            DxEmailRule,
            DxCustomRule,
            DxButton,
            DxCheckBox
        },
        data() {
            return {
                headerRoutes,
                formData: [],
                contacts: null,
                contactMe: null,
                agents: null,
                labelLocation: 'top',
                okMessage: [],
                errorMessage: [],
                buttonOptions: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                editContactId: "",
                isEdit: [],
                phonePattern: /^((1|2|3|4|5|6|7|9|0)\d{10})$/,
                mobileEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату мобильного телефона.'
                },
                phoneEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату телефона.'
                },
                formDataAddContact: {},
                isAdd: false,
                okMessageAddContact: "",
                errorMessageAddContact: "",
                adminRightItems: [
                    {
                        'name': 'Да',
                        'value': 'add'
                    },
                    {
                        'name': 'Нет',
                        'value': 'del'
                    }
                ],
                isAgentEdit: false
            }
        },
        mounted() {
            this.getContacts();
        },
        methods: {
            getContacts: async function () {
                if(this.Agent.isAdmin) {
                    await localStore.dispatch('getAgents', {tokenId: this.Agent.tokenId});
                }

                localStore.dispatch('getContacts', {tokenId: this.Agent.tokenId}).then(() => {
                    if(this.Agent.isAdmin) {
                        this.agents = localStore.state.agents;

                        this.contacts = localStore.state.contacts.map(function(contact) {
                            let agent = this.agents.filter(agent => agent.contactId == contact.contactId);

                            if(agent && agent.length > 0) {
                                return {...contact, ...agent[0]};
                            }

                            return contact;
                        }, this);
                    } else {
                        this.contacts = localStore.state.contacts;
                    }

                    this.contacts.forEach(function (contact) {
                        let phone = this.$options.filters.changeStartEightInPhone(contact.phone);
                        let mobile = this.$options.filters.changeStartEightInPhone(contact.mobile);

                        this.formData[contact.contactId] = {
                            contactName: contact.contactName,
                            position: contact.position,
                            responsibility: contact.responsibility,
                            mobile: mobile,
                            phone: phone,
                            email: contact.email,
                        };

                        this.isEdit[contact.contactId] = false;

                        if(this.Agent.contactId == contact.contactId) {
                            this.contactMe = contact;
                        }
                    }, this);

                    this.contacts = this.contacts.filter(contact => contact.contactId != this.Agent.contactId);

                    this.isAgentEdit = false;

                    this.$store.dispatch('loading', false);
                });
            },
            isReadOnly(contactId) {
                if(this.Agent.isAdmin || contactId == this.Agent.contactId) {
                    return false;
                }

                return true;
            },
            onSubmit: async function (contactId) {
                this.okMessage[contactId] = "";
                this.errorMessage[contactId] = "";
                this.isEdit[contactId] = true;
                this.editContactId = contactId;

                let {
                    contactName,
                    position,
                    responsibility,
                    mobile,
                    phone,
                    email
                } = this.formData[contactId];

                localStore.dispatch('updateContact', {contactId, contactName, position, responsibility, mobile, phone, email, tokenId: this.Agent.tokenId}).then(() => {

                    if(this.Agent.contactId == contactId) {
                        this.$store.commit('setContact', localStore.state.contact);
                    }

                    this.editContactId = "";
                    this.editContactId = contactId;
                    //this.okMessage[contactId] = "Контакт успешно обновлен.";

                    this.getContacts();
                }).catch(this.handleError);
            },
            handleError: function (error) {
                let editContactId = this.editContactId;
                this.editContactId = "";

                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage[editContactId] = errorMessage;
                    } else {
                        this.errorMessage[editContactId] = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessage[editContactId] = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                }

                this.isEdit[editContactId] = false;

                this.editContactId = editContactId;

                console.error(error);
            },
            formFieldDataChanged(contactId) {
                //this.okMessage[contactId] = "";
                //this.errorMessage[contactId] = "";

                this.editContactId = contactId;
            },
            validateRequiredEmail(e) {
                if(this.editContactId == this.Agent.contactId && (e.value == '' || e.value == null)) {
                    return false;
                }

                return true;
            },
            delContact(contactId) {
                let isConfirm = confirm("Вы действительно хотите удалить этот контакт?");
                if(isConfirm) {
                    this.okMessage[contactId] = "";
                    this.errorMessage[contactId] = "";
                    this.isEdit[contactId] = true;
                    this.editContactId = contactId;

                    localStore.dispatch('deleteContact', {tokenId: this.Agent.tokenId, contactId}).then(() => {
                        this.getContacts();
                    }).catch(this.handleErrorDelContact);
                }
            },
            handleErrorDelContact: function (error) {
                let editContactId = this.editContactId;
                this.editContactId = "";

                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage[editContactId] = errorMessage;
                    } else {
                        this.errorMessage[editContactId] = "Ошибка при удалении контакта. Попробуйте еще раз.";
                    }
                } else {
                    this.errorMessage[editContactId] = "Ошибка при удалении контакта. Попробуйте еще раз.";
                }

                this.isEdit[editContactId] = false;

                this.editContactId = editContactId;

                console.error(error);
            },
            onSubmitAddContact: async function () {
                this.errorMessageAddContact = "";
                this.okMessageAddContact = "";
                this.isAdd = true;

                let {
                    contactName,
                    position,
                    responsibility,
                    mobile,
                    phone,
                    email
                } = this.formDataAddContact;

                localStore.dispatch('addContact', {contactName, position, responsibility, mobile, phone, email, tokenId: this.Agent.tokenId}).then(() => {
                    this.isAdd = false;
                    this.formDataAddContact = {};
                    this.okMessageAddContact = "Контакт успешно добавлен.";

                    this.getContacts();
                }).catch(this.handleErrorAdd);
            },
            handleErrorAdd: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageAddContact = errorMessage;
                    } else {
                        this.errorMessageAddContact = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessageAddContact = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                }

                this.isAdd = false;

                console.error(error);
            },
            delAgent(agentId) {
                let isConfirm = confirm("Вы действительно хотите удалить этого пользователя?");
                if(isConfirm) {
                    this.isAgentEdit = true;
                    httpClient.delete(`v2/Agent/${agentId}?tokenId=${this.Agent.tokenId}`).then(() => {
                        this.getContacts();
                    }).catch(this.handleErrorEditAgent);
                }
            },
            adminRight(e) {
                let agentId = e.element.id.replace('adminRight-', '');

                switch (e.value) {
                    case true:
                        this.isAgentEdit = true;

                        httpClient.post(`v2/Agent/${agentId}/AdminRight?tokenId=${this.Agent.tokenId}`).then(() => {
                            this.getContacts();
                        }).catch(this.handleErrorEditAgent);
                        break;
                    case false:
                        this.isAgentEdit = true;

                        httpClient.delete(`v2/Agent/${agentId}/AdminRight?tokenId=${this.Agent.tokenId}`).then(() => {
                            this.getContacts();
                        }).catch(this.handleErrorEditAgent);
                        break;
                }
            },
            agentDisable(agentId) {
                this.isAgentEdit = true;

                httpClient.post(`v2/Agent/${agentId}/Disable?tokenId=${this.Agent.tokenId}`).then(() => {
                    this.getContacts();
                }).catch(this.handleErrorEditAgent);
            },
            agentVerified(agentId) {
                this.isAgentEdit = true;

                httpClient.post(`v2/Agent/${agentId}/Verified?tokenId=${this.Agent.tokenId}`).then(() => {
                    this.getContacts();
                }).catch(this.handleErrorEditAgent);
            },
            handleErrorEditAgent: function (error) {
                console.log(error);
                this.getContacts();
            },
            addNewContact() {
                this.errorMessageAddContact = "";
                this.okMessageAddContact = "";
                this.isAdd = false;
                this.formDataAddContact = {};

                if($('#add-new-contact-div').hasClass('d-none')) {
                    $('#add-new-contact-div').removeClass('d-none');
                } else {
                    $('#add-new-contact-div').removeClass('d-none');
                    $('#add-new-contact-div').addClass('d-none');
                }
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .contacts-form {
        .disable-pt {
            .dx-form-group-with-caption > .dx-form-group-content {
                padding-top: 15px;
            }
        }

        /*.contacts-position {*/
        /*    .dx-texteditor.dx-editor-underlined {*/
        /*        border-bottom: 15px !important;*/
        /*    }*/
        /*}*/
    }

    .card {
        .dx-checkbox {
            padding: 7px;
            border: 1px solid #ddd;
            background: #fff;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
        }

        .dx-checkbox:hover .dx-checkbox-has-text .dx-checkbox-icon {
            border: 1px solid #1e3c69;
        }

        .dx-checkbox-has-text .dx-checkbox-text {
            font-size: 12px;
            font-weight: 600;
            color: #999;
        }

        .dx-checkbox-has-text .dx-checkbox-icon {
            background-color: #dee2e6;
        }

        .dx-checkbox-checked .dx-checkbox-text {
            color: #212529;
        }

        .dx-checkbox-checked .dx-checkbox-icon {
            font: 16 px/16 px DXIcons;
            background-color: #2c5799;
            color: #fff;
            text-align: center;
        }
    }
</style>
